// Overrides
.ev-material {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing {
    border-radius: 0 !important;
  }

  .mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    min-height: auto;
  }
}

.mat-sort-header-arrow {
  color: #fff;
}

.mat-sort-header-container {
  border-bottom: inherit !important;
  user-select: none;
}

.mat-sort-header-content {
  width: 100%;
}
