:root {
    /* BASF Dark Blue */
    --atoms-blue-dark-background: rgb(240, 245, 250);
    --atoms-blue-dark-stroke: #e0e9f1;
    --atoms-basf-hover-blue-dark: rgba(0, 74, 150, 0.6);
    --atoms-basf-hover-background-blue-dark: rgba(240, 245, 250, 0.5);
    --atoms-blue-dark-stroke-hover: #bfd2e5;
    --atoms-blue-dark-shadow: #004a961a; /* rgba(0, 74, 150, 0.1) */

    --atoms-basf-blue-dark-025: #f2f6fa;
    --atoms-basf-blue-dark-040: #F2F6FA; /* rgb(242, 246, 250) */
    --atoms-basf-blue-dark-050: #e5edf4;
    --atoms-basf-blue-dark-075: #e0e9f1;
    --atoms-basf-blue-dark-100: #ccdbea;
    --atoms-basf-blue-dark-125: #bfd2e5;
    --atoms-basf-blue-dark-150: #b2c9df;
    --atoms-basf-blue-dark-175: #a6c0da;
    --atoms-basf-blue-dark-200: #99b7d5;
    --atoms-basf-blue-dark-225: #8caed0;
    --atoms-basf-blue-dark-250: #80a4cb;
    --atoms-basf-blue-dark-275: #739bc5;
    --atoms-basf-blue-dark-300: #6692c0;
    --atoms-basf-blue-dark-325: #5989bb;
    --atoms-basf-blue-dark-350: #4d80b6;
    --atoms-basf-blue-dark-375: #4077b0;
    --atoms-basf-blue-dark-400: #336eab;
    --atoms-basf-blue-dark-425: #2665a6;
    --atoms-basf-blue-dark-450: #1a5ca0;
    --atoms-basf-blue-dark-475: #0d539b;
    --atoms-basf-blue-dark-500: #004a96;
    --atoms-basf-blue-dark-525: #00468e;
    --atoms-basf-blue-dark-550: #004387;
    --atoms-basf-blue-dark-575: #003f80;
    --atoms-basf-blue-dark-600: #003b78;
    --atoms-basf-blue-dark-625: #003871;
    --atoms-basf-blue-dark-650: #003469;
    --atoms-basf-blue-dark-675: #003062;
    --atoms-basf-blue-dark-700: #002c5a;
    --atoms-basf-blue-dark-725: #002953;
    --atoms-basf-blue-dark-750: #00254b;
    --atoms-basf-blue-dark-775: #002144;
    --atoms-basf-blue-dark-800: #001e3c;
    --atoms-basf-blue-dark-825: #001a35;
    --atoms-basf-blue-dark-850: #00162d;
    --atoms-basf-blue-dark-875: #001326;
    --atoms-basf-blue-dark-900: #000f1e;
    --atoms-basf-blue-dark-925: #000b16;
    --atoms-basf-blue-dark-950: #00070f;
    --atoms-basf-blue-dark-975: #000408;

    /* BASF Light Blue */
    --atoms-blue-light-background: rgb(242, 249, 253);
    --atoms-blue-light-stroke: #e0f2f8;
    --atoms-basf-hover-blue-light: rgba(33, 160, 210, 0.6);
    --atoms-basf-hover-background-blue-light: rgba(242, 249, 253, 0.5);
    --atoms-blue-light-stroke-hover: #c7e7f4;
    --atoms-blue-light-shadow: #21a0d21a; /* rgba(33, 160, 210, 0.1) */

    --atoms-basf-blue-light-025: #f4fafd;
    --atoms-basf-blue-light-040: #F4FAFD; /* rgb(244, 250, 253) */
    --atoms-basf-blue-light-050: #e9f5fa;
    --atoms-basf-blue-light-075: #e0f2f8;
    --atoms-basf-blue-light-100: #d3ecf6;
    --atoms-basf-blue-light-125: #c7e7f4;
    --atoms-basf-blue-light-150: #bce2f1;
    --atoms-basf-blue-light-175: #b1deef;
    --atoms-basf-blue-light-200: #a6d9ed;
    --atoms-basf-blue-light-225: #9bd4eb;
    --atoms-basf-blue-light-250: #90cfe9;
    --atoms-basf-blue-light-275: #85cbe6;
    --atoms-basf-blue-light-300: #7ac6e4;
    --atoms-basf-blue-light-325: #6fc1e2;
    --atoms-basf-blue-light-350: #64bde0;
    --atoms-basf-blue-light-375: #59b8dd;
    --atoms-basf-blue-light-400: #4db3db;
    --atoms-basf-blue-light-425: #42aed9;
    --atoms-basf-blue-light-450: #37a9d6;
    --atoms-basf-blue-light-475: #2ca5d4;
    --atoms-basf-blue-light-500: #21a0d2;
    --atoms-basf-blue-light-525: #1f98c7;
    --atoms-basf-blue-light-550: #1e90bd;
    --atoms-basf-blue-light-575: #1c88b3;
    --atoms-basf-blue-light-600: #1a80a8;
    --atoms-basf-blue-light-625: #19789d;
    --atoms-basf-blue-light-650: #177093;
    --atoms-basf-blue-light-675: #156888;
    --atoms-basf-blue-light-700: #14607e;
    --atoms-basf-blue-light-725: #125874;
    --atoms-basf-blue-light-750: #115069;
    --atoms-basf-blue-light-775: #0f485f;
    --atoms-basf-blue-light-800: #0d4054;
    --atoms-basf-blue-light-825: #0c384a;
    --atoms-basf-blue-light-850: #0a303f;
    --atoms-basf-blue-light-875: #082835;
    --atoms-basf-blue-light-900: #07202a;
    --atoms-basf-blue-light-925: #05181f;
    --atoms-basf-blue-light-950: #031015;
    --atoms-basf-blue-light-975: #02080b;

    /* BASF Light green */
    --atoms-green-light-background: rgb(247, 251, 244);
    --atoms-basf-hover-green-light: rgba(101, 172, 30, 0.6);
    --atoms-basf-hover-background-green-light: rgba(247, 251, 244, 0.5);
    --atoms-green-light-stroke: #e0eed4;
    --atoms-green-light-stroke-hover: #d1e6bb;
    --atoms-green-light-shadow: #65ac1e1a; /* rgba(101, 172, 30, 0.1) */

    --atoms-basf-green-light-025: #f7fbf4;
    --atoms-basf-green-light-040: #F7FBF4; /* rgb(247, 251, 244) */
    --atoms-basf-green-light-050: #f0f7e8;
    --atoms-basf-green-light-075: #e8f3dd;
    --atoms-basf-green-light-100: #e0eed4;
    --atoms-basf-green-light-125: #d8eac7;
    --atoms-basf-green-light-150: #d1e6bb;
    --atoms-basf-green-light-175: #c9e2b0;
    --atoms-basf-green-light-200: #c1dea5;
    --atoms-basf-green-light-225: #bada9a;
    --atoms-basf-green-light-250: #b2d58e;
    --atoms-basf-green-light-275: #aad183;
    --atoms-basf-green-light-300: #a3cd78;
    --atoms-basf-green-light-325: #9bc96d;
    --atoms-basf-green-light-350: #93c562;
    --atoms-basf-green-light-375: #8bc156;
    --atoms-basf-green-light-400: #84bd4b;
    --atoms-basf-green-light-425: #7cb840;
    --atoms-basf-green-light-450: #74b435;
    --atoms-basf-green-light-475: #6db029;
    --atoms-basf-green-light-500: #65ac1e;
    --atoms-basf-green-light-525: #60a31d;
    --atoms-basf-green-light-550: #5b9b1b;
    --atoms-basf-green-light-575: #56921a;
    --atoms-basf-green-light-600: #518a18;
    --atoms-basf-green-light-625: #4c8117;
    --atoms-basf-green-light-650: #477815;
    --atoms-basf-green-light-675: #427013;
    --atoms-basf-green-light-700: #3d6712;
    --atoms-basf-green-light-725: #385f11;
    --atoms-basf-green-light-750: #33560f;
    --atoms-basf-green-light-775: #2d4d0e;
    --atoms-basf-green-light-800: #28450c;
    --atoms-basf-green-light-825: #233c0b;
    --atoms-basf-green-light-850: #1e3409;
    --atoms-basf-green-light-875: #192b08;
    --atoms-basf-green-light-900: #142206;
    --atoms-basf-green-light-925: #0f1a04;
    --atoms-basf-green-light-950: #0a1103;
    --atoms-basf-green-light-975: #050902;

    /* BASF Dark green */
    --atoms-green-dark-background: rgb(242, 248, 245);
    --atoms-green-dark-stroke: #d9ebe2;
    --atoms-basf-hover-green-dark: rgba(0, 121, 58, 0.6);
    --atoms-basf-hover-background-green-dark: rgba(242, 248, 245, 0.5);
    --atoms-green-dark-stroke-hover: #bfddce;
    --atoms-green-dark-shadow: #00793a1a; /* rgba(0, 121, 58, 0.1) */

    --atoms-basf-green-dark-025: #f2f8f5;
    --atoms-basf-green-dark-040: #F2F8F5; /* rgb(242, 248, 245) */
    --atoms-basf-green-dark-050: #e5f2eb;
    --atoms-basf-green-dark-075: #d9ebe2;
    --atoms-basf-green-dark-100: #cce4d8;
    --atoms-basf-green-dark-125: #bfddce;
    --atoms-basf-green-dark-150: #b2d7c4;
    --atoms-basf-green-dark-175: #a6d0ba;
    --atoms-basf-green-dark-200: #99c9b0;
    --atoms-basf-green-dark-225: #8cc3a6;
    --atoms-basf-green-dark-250: #80bc9c;
    --atoms-basf-green-dark-275: #73b593;
    --atoms-basf-green-dark-300: #66af89;
    --atoms-basf-green-dark-325: #59a87f;
    --atoms-basf-green-dark-350: #4da175;
    --atoms-basf-green-dark-375: #409a6b;
    --atoms-basf-green-dark-400: #339461;
    --atoms-basf-green-dark-425: #268d58;
    --atoms-basf-green-dark-450: #1a864e;
    --atoms-basf-green-dark-475: #0d8044;
    --atoms-basf-green-dark-500: #0d8044;
    --atoms-basf-green-dark-525: #007337;
    --atoms-basf-green-dark-550: #006d34;
    --atoms-basf-green-dark-575: #006731;
    --atoms-basf-green-dark-600: #00612e;
    --atoms-basf-green-dark-625: #005b2c;
    --atoms-basf-green-dark-650: #005529;
    --atoms-basf-green-dark-675: #004f26;
    --atoms-basf-green-dark-700: #004923;
    --atoms-basf-green-dark-725: #004320;
    --atoms-basf-green-dark-750: #003d1d;
    --atoms-basf-green-dark-775: #00361a;
    --atoms-basf-green-dark-800: #003017;
    --atoms-basf-green-dark-825: #002a14;
    --atoms-basf-green-dark-850: #002411;
    --atoms-basf-green-dark-875: #001e0f;
    --atoms-basf-green-dark-900: #00180c;
    --atoms-basf-green-dark-925: #001209;
    --atoms-basf-green-dark-950: #000c06;
    --atoms-basf-green-dark-975: #000603;

    /* BASF Orange */
    --atoms-orange-background: rgb(254, 250, 241);
    --atoms-orange-stroke: #fdefdb;
    --atoms-basf-hover-orange: rgba(243, 149, 0, 0.6);
    --atoms-basf-hover-background-orange: rgba(254, 250, 241, 0.5);
    --atoms-orange-stroke-hover: #fce5bf;
    --atoms-orange-shadow: #f395001a; /* rgba(243, 149, 0, 0.1) */

    --atoms-basf-orange-025: #fefaf2;
    --atoms-basf-orange-040: #FEFAF2; /* rgb(254, 250, 242) */
    --atoms-basf-orange-050: #fef4e5;
    --atoms-basf-orange-075: #fdefdb;
    --atoms-basf-orange-100: #fdeacc;
    --atoms-basf-orange-125: #fce5bf;
    --atoms-basf-orange-150: #fbdfb2;
    --atoms-basf-orange-175: #fbdaa6;
    --atoms-basf-orange-200: #fad599;
    --atoms-basf-orange-225: #facf8c;
    --atoms-basf-orange-250: #f9ca80;
    --atoms-basf-orange-275: #f8c573;
    --atoms-basf-orange-300: #f8bf66;
    --atoms-basf-orange-325: #f7ba59;
    --atoms-basf-orange-350: #f7b54d;
    --atoms-basf-orange-375: #f6af40;
    --atoms-basf-orange-400: #f5aa33;
    --atoms-basf-orange-425: #f5a526;
    --atoms-basf-orange-450: #f4a01a;
    --atoms-basf-orange-475: #f49a0d;
    --atoms-basf-orange-500: #f39500;
    --atoms-basf-orange-525: #e78e00;
    --atoms-basf-orange-550: #db8600;
    --atoms-basf-orange-575: #cf7f00;
    --atoms-basf-orange-600: #c27700;
    --atoms-basf-orange-625: #b67000;
    --atoms-basf-orange-650: #aa6800;
    --atoms-basf-orange-675: #9e6100;
    --atoms-basf-orange-700: #925900;
    --atoms-basf-orange-725: #865200;
    --atoms-basf-orange-750: #7a4b00;
    --atoms-basf-orange-775: #6d4300;
    --atoms-basf-orange-800: #613c00;
    --atoms-basf-orange-825: #553400;
    --atoms-basf-orange-850: #492d00;
    --atoms-basf-orange-875: #3d2500;
    --atoms-basf-orange-900: #311e00;
    --atoms-basf-orange-925: #241600;
    --atoms-basf-orange-950: #180f00;
    --atoms-basf-orange-975: #0c0700;

    /* BASF Red */
    --atoms-basf-hover-red: rgba(209, 51, 78, 0.6);
    --atoms-basf-hover-background-red: rgba(252, 243, 244, 0.5);
    --atoms-red-background: rgb(252, 243, 244);
    --atoms-red-stroke: #f8e0e4;
    --atoms-red-stroke-hover: #f1bfc8;
    --atoms-red-shadow: #c500221a; /* rgba(197, 0, 34, 0.1) */

    --atoms-basf-red-025: #fcf2f4;
    --atoms-basf-red-040: #FCF2F4; /* rgb(252, 242, 244) */
    --atoms-basf-red-050: #f9e5e9;
    --atoms-basf-red-100: #f3ccd3;
    --atoms-basf-red-125: #f1bfc8;
    --atoms-basf-red-150: #eeb2bd;
    --atoms-basf-red-175: #eba6b2;
    --atoms-basf-red-200: #e899a7;
    --atoms-basf-red-225: #e58c9c;
    --atoms-basf-red-250: #e28090;
    --atoms-basf-red-275: #df7385;
    --atoms-basf-red-300: #dc667a;
    --atoms-basf-red-325: #d9596f;
    --atoms-basf-red-350: #d64d64;
    --atoms-basf-red-375: #d34059;
    --atoms-basf-red-400: #d1334e;
    --atoms-basf-red-425: #ce2643;
    --atoms-basf-red-450: #cb1a38;
    --atoms-basf-red-475: #c80d2d;
    --atoms-basf-red-500: #c50022;
    --atoms-basf-red-525: #bb0020;
    --atoms-basf-red-550: #b1001f;
    --atoms-basf-red-575: #a7001d;
    --atoms-basf-red-600: #9e001b;
    --atoms-basf-red-625: #94001a;
    --atoms-basf-red-650: #8a0018;
    --atoms-basf-red-675: #800016;
    --atoms-basf-red-700: #760014;
    --atoms-basf-red-725: #6c0013;
    --atoms-basf-red-750: #630011;
    --atoms-basf-red-775: #59000f;
    --atoms-basf-red-800: #4f000e;
    --atoms-basf-red-825: #45000c;
    --atoms-basf-red-850: #3b000a;
    --atoms-basf-red-875: #310009;
    --atoms-basf-red-900: #270007;
    --atoms-basf-red-925: #1e0005;
    --atoms-basf-red-950: #140003;
    --atoms-basf-red-975: #0a0002;

    /* BASF gray */
    --atoms-gray-background: rgb(237, 237, 237);
    --atoms-gray-stroke: #ececec;
    --atoms-gray-stroke-hover: #e7e7e7;
    --atoms-basf-hover-gray: rgba(158, 158, 158, 0.6);
    --atoms-basf-hover-background-gray: rgba(237, 237, 237, 0.5);
    --atoms-gray-shadow: #9e9e9e1a; /* rgba(55, 55, 55, 0.1) */

    --atoms-basf-gray-025: #fafafa;
    --atoms-basf-gray-040: #FAFAFA; /* rgb(250, 250, 250) */
    --atoms-basf-gray-050: #f5f5f5;
    --atoms-basf-gray-075: #ececec;
    --atoms-basf-gray-100: #ececec;
    --atoms-basf-gray-125: #e7e7e7;
    --atoms-basf-gray-150: #e2e2e2;
    --atoms-basf-gray-175: #dcdcdc;
    --atoms-basf-gray-200: #d8d8d8;
    --atoms-basf-gray-225: #d3d3d3;
    --atoms-basf-gray-250: #cfcfcf;
    --atoms-basf-gray-275: #cacaca;
    --atoms-basf-gray-300: #c5c5c5;
    --atoms-basf-gray-325: #c0c0c0;
    --atoms-basf-gray-350: #bbbbbb;
    --atoms-basf-gray-375: #b6b6b6;
    --atoms-basf-gray-400: #b1b1b1;
    --atoms-basf-gray-425: #adadad;
    --atoms-basf-gray-450: #a8a8a8;
    --atoms-basf-gray-475: #a3a3a3;
    --atoms-basf-gray-500: #9e9e9e;
    --atoms-basf-gray-525: #969696;
    --atoms-basf-gray-550: #8e8e8e;
    --atoms-basf-gray-575: #868686;
    --atoms-basf-gray-600: #7e7e7e;
    --atoms-basf-gray-625: #777777;
    --atoms-basf-gray-650: #6f6f6f;
    --atoms-basf-gray-675: #676767;
    --atoms-basf-gray-700: #5f5f5f;
    --atoms-basf-gray-725: #575757;
    --atoms-basf-gray-750: #4d4d4d;
    --atoms-basf-gray-775: #474747;
    --atoms-basf-gray-800: #3f3f3f;
    --atoms-basf-gray-825: #373737;
    --atoms-basf-gray-850: #2f2f2f;
    --atoms-basf-gray-875: #282828;
    --atoms-basf-gray-900: #202020;
    --atoms-basf-gray-925: #181818;
    --atoms-basf-gray-950: #101010;
    --atoms-basf-gray-975: #080808;

    /* Other colors */
    --atoms-warn-color: var(--atoms-basf-red-500);
    --atoms-background-hover-color: #cfcfcf;
    --atoms-white: white;
    --atoms-box-gray: var(--atoms-basf-gray-075); /* rgb(220, 220, 220) */
    --atoms-light-gray: var(--atoms-basf-gray-175); /* rgb(231, 231, 231) */
    --atoms-middle-gray: var(--atoms-basf-gray-425);
    --atoms-dark-gray: #4d4d4d;
    --atoms-black: var(--atoms-basf-gray-900);
    --atoms-text-color: var(--atoms-basf-gray-825);
    --atoms-input-border-color: #dcdcdc;
    --atoms-basf-body-background: #e5e5e5;
    --atoms-invalid-state-background-color: #f8e0e4;
    --atoms-invalid-state-border-color: #c50022;
    --theme-error: var(--atoms-basf-red-500);
}
