@use 'libs/ev/frontend/ui-library/src/lib/variables.scss' as vars;

@import 'libs/ev/frontend/ui-library/src/lib/styles/dark-blue-styles.scss';

@tailwind components;
@tailwind utilities;

.bg-primary {
  background-color: vars.$primaryColor !important;
}

.text-primary {
  color: vars.$primaryColor !important;
}

// TODO: Needed because atoms config adds margin bottom which does not make sense
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0 !important;
}

.p-progressbar {
  height: 6px !important;
}

html { height: 100%; }
body { height: 100%; overflow:auto; background: #f3f3f3; }
