:root {
    /* by default using the a11y theme */
    --atoms-primary-color: #000000;
    --atoms-secondary-color: #000000;
    --atoms-pale-color: #ececec;
    --atoms-hover-color: #dcdcdc;
    --atoms-shadow-color: #ffffff;
    --atoms-basf-color-040: #ffffff;
    --atoms-basf-color-050: #ffffff;
    --atoms-basf-color-100: #ffffff;
    --atoms-basf-color-150: #ffffff;
    --atoms-basf-color-200: #ffffff;
    --atoms-basf-color-300: #7f7f7f;
    --atoms-basf-color-400: #7f7f7f;
    --atoms-basf-color-450: #7f7f7f;
    --atoms-basf-color-500: #7f7f7f;
    --atoms-basf-color-600: #7f7f7f;
    --atoms-basf-color-700: #000000;
    --atoms-basf-color-800: #000000;
    --atoms-basf-color-900: #000000;
    --atoms-primary-text: #ffffff;
}

.theme-dark-blue {
    --atoms-primary-color: var(--atoms-basf-blue-dark-500);
    --atoms-secondary-color: var(--atoms-basf-blue-dark-425);
    --atoms-pale-color: var(--atoms-basf-blue-dark-075);
    --atoms-hover-color: var(--atoms-basf-hover-blue-dark);
    --atoms-shadow-color: var(--atoms-basf-blue-dark-025);

    --atoms-background-color: var(--atoms-blue-dark-background);
    --atoms-stroke-color: var(--atoms-blue-dark-stroke);
    --atoms-stroke-color-hover: var(--atoms-blue-dark-stroke-hover);
    --atoms-hover-background-color: var(--atoms-basf-blue-dark-025);

    --atoms-basf-color-040: var(--atoms-basf-blue-dark-040);
    --atoms-basf-color-050: var(--atoms-basf-blue-dark-050);
    --atoms-basf-color-100: var(--atoms-basf-blue-dark-100);
    --atoms-basf-color-150: var(--atoms-basf-blue-dark-150);
    --atoms-basf-color-200: var(--atoms-basf-blue-dark-200);
    --atoms-basf-color-300: var(--atoms-basf-blue-dark-300);
    --atoms-basf-color-400: var(--atoms-basf-blue-dark-400);
    --atoms-basf-color-450: var(--atoms-basf-blue-dark-450);
    --atoms-basf-color-500: var(--atoms-basf-blue-dark-500);
    --atoms-basf-color-600: var(--atoms-basf-blue-dark-600);
    --atoms-basf-color-700: var(--atoms-basf-blue-dark-700);
    --atoms-basf-color-800: var(--atoms-basf-blue-dark-800);
    --atoms-basf-color-900: var(--atoms-basf-blue-dark-900);
    --atoms-primary-text: #ffffff;
}

.theme-light-blue {
    --atoms-primary-color: var(--atoms-basf-blue-light-500);
    --atoms-secondary-color: var(--atoms-basf-blue-light-375);
    --atoms-pale-color: var(--atoms-basf-blue-light-075);
    --atoms-hover-color: var(--atoms-basf-hover-blue-light);
    --atoms-shadow-color: var(--atoms-basf-blue-light-025);

    --atoms-background-color: var(--atoms-blue-light-background);
    --atoms-stroke-color: var(--atoms-blue-light-stroke);
    --atoms-stroke-color-hover: var(--atoms-blue-light-stroke-hover);
    --atoms-hover-background-color: var(--atoms-basf-blue-light-025);

    --atoms-basf-color-040: var(--atoms-basf-blue-light-040);
    --atoms-basf-color-050: var(--atoms-basf-blue-light-050);
    --atoms-basf-color-100: var(--atoms-basf-blue-light-100);
    --atoms-basf-color-150: var(--atoms-basf-blue-light-150);
    --atoms-basf-color-200: var(--atoms-basf-blue-light-200);
    --atoms-basf-color-300: var(--atoms-basf-blue-light-300);
    --atoms-basf-color-400: var(--atoms-basf-blue-light-400);
    --atoms-basf-color-450: var(--atoms-basf-blue-light-450);
    --atoms-basf-color-500: var(--atoms-basf-blue-light-500);
    --atoms-basf-color-600: var(--atoms-basf-blue-light-600);
    --atoms-basf-color-700: var(--atoms-basf-blue-light-700);
    --atoms-basf-color-800: var(--atoms-basf-blue-light-800);
    --atoms-basf-color-900: var(--atoms-basf-blue-light-900);
    --atoms-primary-text: #ffffff;
}

.theme-dark-green {
    --atoms-primary-color: var(--atoms-basf-green-dark-500);
    --atoms-secondary-color: var(--atoms-basf-green-dark-375);
    --atoms-pale-color: var(--atoms-basf-green-dark-075);
    --atoms-hover-color: var(--atoms-basf-hover-green-dark);
    --atoms-shadow-color: var(--atoms-basf-green-dark-025);

    --atoms-background-color: var(--atoms-green-dark-background);
    --atoms-stroke-color: var(--atoms-green-dark-stroke);
    --atoms-stroke-color-hover: var(--atoms-green-dark-stroke-hover);
    --atoms-hover-background-color: var(--atoms-basf-green-dark-025);

    --atoms-basf-color-040: var(--atoms-basf-green-dark-040);
    --atoms-basf-color-050: var(--atoms-basf-green-dark-050);
    --atoms-basf-color-100: var(--atoms-basf-green-dark-100);
    --atoms-basf-color-150: var(--atoms-basf-green-dark-150);
    --atoms-basf-color-200: var(--atoms-basf-green-dark-200);
    --atoms-basf-color-300: var(--atoms-basf-green-dark-300);
    --atoms-basf-color-400: var(--atoms-basf-green-dark-400);
    --atoms-basf-color-450: var(--atoms-basf-green-dark-450);
    --atoms-basf-color-500: var(--atoms-basf-green-dark-500);
    --atoms-basf-color-600: var(--atoms-basf-green-dark-600);
    --atoms-basf-color-700: var(--atoms-basf-green-dark-700);
    --atoms-basf-color-800: var(--atoms-basf-green-dark-800);
    --atoms-basf-color-900: var(--atoms-basf-green-dark-900);
    --atoms-primary-text: #ffffff;
}

.theme-light-green {
    --atoms-primary-color: var(--atoms-basf-green-light-500);
    --atoms-secondary-color: var(--atoms-basf-green-light-375);
    --atoms-pale-color: var(--atoms-basf-green-light-100);
    --atoms-hover-color: var(--atoms-basf-hover-green-light);
    --atoms-shadow-color: var(--atoms-basf-green-light-025);

    --atoms-background-color: var(--atoms-green-light-background);
    --atoms-stroke-color: var(--atoms-green-light-stroke);
    --atoms-stroke-color-hover: var(--atoms-green-light-stroke-hover);
    --atoms-hover-background-color: var(--atoms-basf-green-light-025);

    --atoms-basf-color-040: var(--atoms-basf-green-light-040);
    --atoms-basf-color-050: var(--atoms-basf-green-light-050);
    --atoms-basf-color-100: var(--atoms-basf-green-light-100);
    --atoms-basf-color-150: var(--atoms-basf-green-light-150);
    --atoms-basf-color-200: var(--atoms-basf-green-light-200);
    --atoms-basf-color-300: var(--atoms-basf-green-light-300);
    --atoms-basf-color-400: var(--atoms-basf-green-light-400);
    --atoms-basf-color-450: var(--atoms-basf-green-light-450);
    --atoms-basf-color-500: var(--atoms-basf-green-light-500);
    --atoms-basf-color-600: var(--atoms-basf-green-light-600);
    --atoms-basf-color-700: var(--atoms-basf-green-light-700);
    --atoms-basf-color-800: var(--atoms-basf-green-light-800);
    --atoms-basf-color-900: var(--atoms-basf-green-light-900);
    --atoms-primary-text: #ffffff;
}

.theme-orange {
    --atoms-primary-color: var(--atoms-basf-orange-500);
    --atoms-secondary-color: var(--atoms-basf-orange-375);
    --atoms-pale-color: var(--atoms-basf-orange-075);
    --atoms-hover-color: var(--atoms-basf-hover-orange);
    --atoms-shadow-color: var(--atoms-basf-orange-025);

    --atoms-background-color: var(--atoms-orange-background);
    --atoms-stroke-color: var(--atoms-orange-stroke);
    --atoms-stroke-color-hover: var(--atoms-orange-stroke-hover);
    --atoms-hover-background-color: var(--atoms-basf-orange-025);

    --atoms-basf-color-040: var(--atoms-basf-orange-040);
    --atoms-basf-color-050: var(--atoms-basf-orange-050);
    --atoms-basf-color-100: var(--atoms-basf-orange-100);
    --atoms-basf-color-150: var(--atoms-basf-orange-150);
    --atoms-basf-color-200: var(--atoms-basf-orange-200);
    --atoms-basf-color-300: var(--atoms-basf-orange-300);
    --atoms-basf-color-400: var(--atoms-basf-orange-400);
    --atoms-basf-color-450: var(--atoms-basf-orange-450);
    --atoms-basf-color-500: var(--atoms-basf-orange-500);
    --atoms-basf-color-600: var(--atoms-basf-orange-600);
    --atoms-basf-color-700: var(--atoms-basf-orange-700);
    --atoms-basf-color-800: var(--atoms-basf-orange-800);
    --atoms-basf-color-900: var(--atoms-basf-orange-900);
    --atoms-primary-text: #ffffff;
}

.theme-red {
    --atoms-primary-color: var(--atoms-basf-red-500);
    --atoms-secondary-color: var(--atoms-basf-red-375);
    --atoms-pale-color: var(--atoms-basf-red-075);
    --atoms-hover-color: var(--atoms-basf-hover-red);
    --atoms-shadow-color: var(--atoms-basf-red-025);

    --atoms-background-color: var(--atoms-red-background);
    --atoms-stroke-color: var(--atoms-red-stroke);
    --atoms-stroke-color-hover: var(--atoms-red-stroke-hover);
    --atoms-hover-background-color: var(--atoms-basf-red-025);

    --atoms-basf-color-040: var(--atoms-basf-red-040);
    --atoms-basf-color-050: var(--atoms-basf-red-050);
    --atoms-basf-color-100: var(--atoms-basf-red-100);
    --atoms-basf-color-150: var(--atoms-basf-red-150);
    --atoms-basf-color-200: var(--atoms-basf-red-200);
    --atoms-basf-color-300: var(--atoms-basf-red-300);
    --atoms-basf-color-400: var(--atoms-basf-red-400);
    --atoms-basf-color-450: var(--atoms-basf-red-450);
    --atoms-basf-color-500: var(--atoms-basf-red-500);
    --atoms-basf-color-600: var(--atoms-basf-red-600);
    --atoms-basf-color-700: var(--atoms-basf-red-700);
    --atoms-basf-color-800: var(--atoms-basf-red-800);
    --atoms-basf-color-900: var(--atoms-basf-red-900);
    --atoms-primary-text: #ffffff;
}

.theme-text-color {
    --atoms-primary-color: var(--atoms-white);
}

.theme-a11y {
    --atoms-primary-color: #000000;
    --atoms-secondary-color: #000000;
    --atoms-pale-color: #ececec;
    --atoms-hover-color: var(--atoms-basf-hover-gray);
    --atoms-shadow-color: #ffffff;

    --atoms-background-color: var(--atoms-gray-background);
    --atoms-stroke-color: var(--atoms-gray-stroke);
    --atoms-stroke-color-hover: var(--atoms-gray-stroke-hover);
    --atoms-hover-background-color: var(--atoms-basf-hover-background-gray);

    --atoms-basf-color-040: #ffffff;
    --atoms-basf-color-050: #ffffff;
    --atoms-basf-color-100: #ffffff;
    --atoms-basf-color-150: #ffffff;
    --atoms-basf-color-200: #ffffff;
    --atoms-basf-color-300: #7f7f7f;
    --atoms-basf-color-400: #7f7f7f;
    --atoms-basf-color-450: #7f7f7f;
    --atoms-basf-color-500: #7f7f7f;
    --atoms-basf-color-600: #7f7f7f;
    --atoms-basf-color-700: #000000;
    --atoms-basf-color-800: #000000;
    --atoms-basf-color-900: #000000;

    --theme-error: #000000;

    img,
    video,
    iframe {
        filter: grayscale(1);
    }

    a {
        text-decoration: underline;
    }
}
