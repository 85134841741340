@import "./basf-colors.css";
@import "./basf-themes.css";
@import "./atoms-icons.css";

@font-face {
  font-family: "Roboto Flex";
  src: url("./fonts/RobotoFlex-Regular.ttf") format("truetype");
}

html {
  font-family: "Roboto Flex";
}

.brand-color {
  color: var(--atoms-primary-color) !important;
}

/* class from primeicons that allows the icons to have a spin animation */
.pi-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/*  */
