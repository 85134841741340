@font-face {
  font-family: "atoms-icons";
  src: url("./fonts/atoms-icons.eot?wsfyq8");
  src: url("./fonts/atoms-icons.eot?wsfyq8#iefix") format("embedded-opentype"), url("./fonts/atoms-icons.ttf?wsfyq8") format("truetype"),
    url("./fonts/atoms-icons.woff?wsfyq8") format("woff"), url("./fonts/atoms-icons.svg?wsfyq8#atoms-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ai-"],
[class*=" ai-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "atoms-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ai-pinterest:before {
  content: "\e95e";
}
.ai-wechat:before {
  content: "\e97c";
}
.ai-pdf:before {
  content: "\e95b";
}
.ai-google-plus:before {
  content: "\e93d";
}
.ai-slideshare:before {
  content: "\e96e";
}
.ai-instagram:before {
  content: "\e941";
}
.ai-facebook:before {
  content: "\e942";
}
.ai-facebook-filled:before {
  content: "\e949";
}
.ai-instagram-outline:before {
  content: "\e966";
}
.ai-linkedin:before {
  content: "\e97d";
}
.ai-renren:before {
  content: "\e97e";
}
.ai-weibo:before {
  content: "\e981";
}
.ai-whatsapp:before {
  content: "\e982";
}
.ai-xing:before {
  content: "\e983";
}
.ai-youku:before {
  content: "\e984";
}
.ai-youtube:before {
  content: "\e986";
}
.ai-youtube-image-brand:before {
  content: "\e987";
}
.ai-tag:before {
  content: "\e988";
}
.ai-spinner:before {
  content: "\e989";
}
.ai-accessibility:before {
  content: "\e900";
}
.ai-add:before {
  content: "\e901";
}
.ai-app-drawer:before {
  content: "\e902";
}
.ai-arrow-down:before {
  content: "\e903";
}
.ai-arrow-down-left:before {
  content: "\e904";
}
.ai-arrow-down-left-thin:before {
  content: "\e905";
}
.ai-arrow-down-right-thin:before {
  content: "\e906";
}
.ai-arrow-down-thin:before {
  content: "\e907";
}
.ai-arrow-jump-back:before {
  content: "\e908";
}
.ai-arrow-left:before {
  content: "\e909";
}
.ai-arrow-left-thin:before {
  content: "\e90a";
}
.ai-arrow-right:before {
  content: "\e90b";
}
.ai-arrow-right-thin:before {
  content: "\e90c";
}
.ai-arrow-up:before {
  content: "\e90d";
}
.ai-arrow-up-left:before {
  content: "\e90e";
}
.ai-arrow-up-left-thin:before {
  content: "\e90f";
}
.ai-arrow-up-right:before {
  content: "\e910";
}
.ai-arrow-up-right-thin:before {
  content: "\e911";
}
.ai-arrow-up-thin:before {
  content: "\e912";
}
.ai-attachment:before {
  content: "\e913";
}
.ai-attention:before {
  content: "\e914";
}
.ai-back:before {
  content: "\e915";
}
.ai-back-double:before {
  content: "\e916";
}
.ai-back-thin:before {
  content: "\e917";
}
.ai-blog:before {
  content: "\e918";
}
.ai-bookmark:before {
  content: "\e919";
}
.ai-break:before {
  content: "\e91a";
}
.ai-calendar:before {
  content: "\e91b";
}
.ai-cancel:before {
  content: "\e91c";
}
.ai-cart:before {
  content: "\e91d";
}
.ai-certificate:before {
  content: "\e91e";
}
.ai-check:before {
  content: "\e91f";
}
.ai-check-circle:before {
  content: "\e920";
}
.ai-close:before {
  content: "\e921";
}
.ai-cloud:before {
  content: "\e922";
}
.ai-comment:before {
  content: "\e923";
}
.ai-counter:before {
  content: "\e924";
}
.ai-down:before {
  content: "\e925";
}
.ai-download:before {
  content: "\e926";
}
.ai-drag:before {
  content: "\e927";
}
.ai-drag-text:before {
  content: "\e928";
}
.ai-drag-points:before {
  content: "\e929";
}
.ai-dropdown-down:before {
  content: "\e92a";
}
.ai-dropdown-up:before {
  content: "\e92b";
}
.ai-dropdown-right:before {
  content: "\e92c";
}
.ai-dropdown-left:before {
  content: "\e92d";
}
.ai-edit:before {
  content: "\e92e";
}
.ai-equal:before {
  content: "\e92f";
}
.ai-error-circle:before {
  content: "\e930";
}
.ai-external-link:before {
  content: "\e931";
}
.ai-external-link-global-home:before {
  content: "\e932";
}
.ai-eye:before {
  content: "\e933";
}
.ai-eye-closed:before {
  content: "\e934";
}
.ai-fax:before {
  content: "\e935";
}
.ai-filter:before {
  content: "\e936";
}
.ai-flickr:before {
  content: "\e937";
}
.ai-forward:before {
  content: "\e938";
}
.ai-forward-double:before {
  content: "\e939";
}
.ai-forward-thin:before {
  content: "\e93a";
}
.ai-full-view:before {
  content: "\e93b";
}
.ai-full-view-end:before {
  content: "\e93c";
}
.ai-heart:before {
  content: "\e93e";
}
.ai-home:before {
  content: "\e93f";
}
.ai-info:before {
  content: "\e940";
}
.ai-key:before {
  content: "\e943";
}
.ai-key-default:before {
  content: "\e944";
}
.ai-letter:before {
  content: "\e945";
}
.ai-light-on:before {
  content: "\e946";
}
.ai-link-circle:before {
  content: "\e947";
}
.ai-link-circle-outline:before {
  content: "\e948";
}
.ai-list-view:before {
  content: "\e94a";
}
.ai-location:before {
  content: "\e94b";
}
.ai-lock:before {
  content: "\e94c";
}
.ai-lock-open:before {
  content: "\e94d";
}
.ai-medal:before {
  content: "\e94e";
}
.ai-menu:before {
  content: "\e94f";
}
.ai-menu-alt:before {
  content: "\e950";
}
.ai-menu-more:before {
  content: "\e951";
}
.ai-messenger:before {
  content: "\e952";
}
.ai-minus:before {
  content: "\e953";
}
.ai-minus-circle:before {
  content: "\e954";
}
.ai-mobile:before {
  content: "\e955";
}
.ai-more-options:before {
  content: "\e956";
}
.ai-movie:before {
  content: "\e957";
}
.ai-newwindow:before {
  content: "\e958";
}
.ai-notification:before {
  content: "\e959";
}
.ai-pause-circle:before {
  content: "\e95a";
}
.ai-picture:before {
  content: "\e95c";
}
.ai-picture-slideshow:before {
  content: "\e95d";
}
.ai-play:before {
  content: "\e95f";
}
.ai-play-circle:before {
  content: "\e960";
}
.ai-plus-circle:before {
  content: "\e961";
}
.ai-print:before {
  content: "\e962";
}
.ai-puzzle:before {
  content: "\e963";
}
.ai-question:before {
  content: "\e964";
}
.ai-reload:before {
  content: "\e965";
}
.ai-search:before {
  content: "\e967";
}
.ai-security:before {
  content: "\e968";
}
.ai-send:before {
  content: "\e969";
}
.ai-settings:before {
  content: "\e96a";
}
.ai-share:before {
  content: "\e96b";
}
.ai-share-global:before {
  content: "\e96c";
}
.ai-sitemap:before {
  content: "\e96d";
}
.ai-sound-off:before {
  content: "\e96f";
}
.ai-sound-on:before {
  content: "\e970";
}
.ai-star:before {
  content: "\e971";
}
.ai-tap:before {
  content: "\e972";
}
.ai-target:before {
  content: "\e973";
}
.ai-telephone:before {
  content: "\e974";
}
.ai-time:before {
  content: "\e975";
}
.ai-trash:before {
  content: "\e976";
}
.ai-up:before {
  content: "\e977";
}
.ai-upload:before {
  content: "\e978";
}
.ai-user:before {
  content: "\e979";
}
.ai-user-fill:before {
  content: "\e97a";
}
.ai-user-outline:before {
  content: "\e97b";
}
.ai-wifi:before {
  content: "\e97f";
}
.ai-world:before {
  content: "\e980";
}
.ai-zoom:before {
  content: "\e985";
}
