@use '../variables.scss' as vars;

@import 'https://cdn.jsdelivr.net/npm/shepherd.js@10.0.1/dist/css/shepherd.css';

// Shepherd stylings
.shepherd-element {
  border-radius: 0;

  .shepherd-button {
    height: 40px;
    min-width: 80px;
    background-color: vars.$primaryColor;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 0 16px;
    border-radius: 0;

    &:not(:disabled):hover {
      background-color: vars.$hoverColor;
      color: #fff;
    }
  }
}
